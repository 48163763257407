import {localStorageService} from "./localStorgeService";

export const UserRoles = {
    ADMIN :"ADMIN", // can only approve or dissapprove applicants //  cant see the staff
    OWNER :"OWNER", // can do everything
    USER_MANAGEMENT:"USER_MANAGEMENT" // can do everything except deactivate a user
}
export  const getUserRoleDescription =(role)=>{

    switch (role) {
        case UserRoles.ADMIN:
            return "Help desk";

        case UserRoles.USER_MANAGEMENT:
            return "User management";
        case UserRoles.OWNER:
            return "Super user";
        default:
            return "I dont know, correct me please";


    }
}

export const getCurrentUser =()=>{

    let user  =  {
        isLoggedIn : false,
        accessToken: "",
        firstName :"",
        roles: []
    };

    if(localStorageService.getItem("user")!== null)
    {
        user =  JSON.parse(localStorageService.getItem("user"));
    }
    return user;

}
export const  setCurrentUser=(user = {isLoggedIn : false,
    accessToken: "",
    firstName :"",
    roles: []
}) =>  {


    localStorageService.setItem("user", JSON.stringify(user));

}