import React, {Fragment, useState} from 'react';
import {Button} from "react-bootstrap";
const FilesGroup =(props)=>{

    let numItemsRendered = 0
const addFile=(event)=>{

    event.preventDefault();
    if(canAddMore())
    {
        props.addFileInGroup(props.fileType);
    }

};

const canAddMore = ()=>{

    let currentFilesOfThisGroup = 0;
    for(let i = 0; i< props.files.length; i++)
    {
        if(props.files[i].type === props.fileType)
        {
            currentFilesOfThisGroup ++;
        }
    }
    return currentFilesOfThisGroup < props.maxCount;
};


return(
    <Fragment>

        <label>{props.title}</label>
        {
            props.files.map(file => {
                if(file.type !== props.fileType)
                    return "";
                numItemsRendered++;

                return (
                    <div className='custom-file mb-4' key={file.name}>
                    <input
                        type='file'
                        className='custom-file-input'
                        name={file.name}
                        onChange={props.onChange}
                        required={numItemsRendered === 1}
                        accept= "application/pdf,application/x-pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                        {file.filename}
                    </label>
                </div>
                )

            })
        }
        {
            canAddMore()&&
            <div className="text-right">
                <Button className="btn-secondary" onClick={addFile}>Add another file</Button>
            </div>
        }
        <br/>

    </Fragment>


)




};

export default  FilesGroup;