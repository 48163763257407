import React, {useState} from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {loginUser} from "../actions";
import Progress from "../components/Progress";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
const Recover  = ()=> {

    const [validated, setValidated]  = useState(false);
    const [emailSent , setEmailSent] = useState(false);
    const [emailNotFound,setEmailNotFound] = useState(false);

    const [inputs, setInputs] = useState({});
    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const dispatch = useDispatch();
    const handleInputChange=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputs(inputs => ({...inputs, [name]: value}));

    };

    const handleSubmit = (event)=>
    {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            setShowProgress(true);
            let data ={...inputs,appId:Site.clientId};
            axios.post(  API_ROOT_URL +"/user/recover/",  data ,{
                onUploadProgress: progressEvent => {
                setUploadPercentage(
                    parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    )
                );
            }
            })
                .then(res => {

                    setEmailSent(true);
                    setShowProgress(false);
                    // console.log(res.data);


                }).catch((error) => {
                setShowProgress(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if(error.response.status === 404)
                    {

                        setEmailNotFound(true);

                    }

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
        setValidated(true);


    };

    if(showProgress)
    {
        return  (<Progress percentage={uploadPercentage} />);

    }


    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        {
                            !emailSent &&
                            <React.Fragment>
                        <h1>Please enter your email address</h1>
                                {
                                    emailNotFound &&  <Alert key="errormessage" variant="danger">
                                        Cant find the email in the database, please try another one
                                    </Alert>
                                }

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group   controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    defaultValue={inputs.email}
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>

                            <Button className="btn-cta" type="submit">Recover password</Button>
                        </Form>
                            </React.Fragment>
                            }

                        {
                            emailSent && <React.Fragment>
                                <h1>Recovery code sent to your email</h1>
                                <p>
                                    We have sent the recovery pass code to your email<br/>
                                    Please check your email.

                                </p>

                            </React.Fragment>
                        }


                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};
export default Recover;