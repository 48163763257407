import React from 'react';
import {Card, Col, Container, Row, Table} from "react-bootstrap";

import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
import {CompanyAddress} from "../components/CompanyInfo";


const Contact = () => {
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1 dir="ltr">Contact us</h1>
                        <p>Location:<br/> <CompanyAddress/></p>
                        <p>Telephone:<br/> <a href={"tel:"+Site.phoneNumber}>{Site.phoneNumber}</a> / <a href={"tel:"+Site.alternatePhoneNumber}>{Site.alternatePhoneNumber}</a></p>
                        <p>Email:<br/> <a href={"mailto:"+Site.contactEmail}>{Site.contactEmail}</a></p>
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>);

};
export default Contact;