import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import EmploymentForm from "../components/EmploymentForm";
import FilesForm from "../components/FilesForm";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {removeTimeFromDateTime} from "../util/Date";
import { Switch, Route ,Redirect } from 'react-router-dom'
import {useSelector , useDispatch} from "react-redux";
import {updateApplicationStatus} from "../actions";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";

const  Application =()=>{

    const currentUser = useSelector(state => state.user);
    const applicationState = useSelector(state =>state.applicationStatus);
    const dispatch = useDispatch();

    useEffect(()=>{


        let data ={appId:Site.clientId,accessToken:currentUser.accessToken};
        //alert(JSON.stringify(data)); return;
        axios.post(  API_ROOT_URL +"/application/status/",  data )
            .then(res => {
                let data = {...res.data};

                //alert(JSON.stringify(data));
                dispatch(updateApplicationStatus(data))
                /*
               if( data.employment === 1)
               {
                   setEmploymentSectionComplete(true);
               }
               if(data.files === 1)
               {
                   setFileSectionComplete(true);
               }
               if(data.emailVerified ===1)
               {
                   setEmailVerified(true);
               }

                 */

                // console.log(res.data);


            }).catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                if(error.response.status === 404)
                {


                }
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */

            } else {
                // Something happened in setting up the request and triggered an Error

            }
        });


    },[]);

    if(!currentUser.isLoggedIn)
         return <Redirect to="/login" />;

    return (
        <ApplicantLayout>
        <Container><Row><Col>
        <h1>Application</h1>
        {
            applicationState.emailVerified===0&&<Alert key="errormessage" variant="danger">
               Hie {currentUser.firstName}! we have sent you an email, please verify and continue with the application
            </Alert>
        }
        {
            (applicationState.emailVerified===1 && applicationState.employment===1 && applicationState.files ===1)&&
            <p>Thanks for your application, your application has been successfully submitted</p>
        }
        {
            (applicationState.emailVerified===1&&applicationState.files===0&&applicationState.employment===1)&&
            <FilesForm/>
        }
        {
            applicationState.emailVerified===1&& applicationState.employment===0&& <EmploymentForm/>
        }
    </Col></Row></Container> </ApplicantLayout>)

};
export default Application;