import React from "react";
import {Col, Container, Image, Row, Card,Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
import {AdminUrls} from "../urls";
import CariCareHomeContent from "../components/home/CaricareHomeContent";
import UkareHomeContent from "../components/home/UkareHomeContent";

const Home = () => {
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const applicationState = useSelector(state => state.applicationStatus);

    const backgroundStyle = Site.clientId==="ukare"?{backgroundImage: 'url("/images/ukare/321233603.jpg"',backgroundPosition:"center"}:{};

    return (

        <ApplicantLayout>
            { isLoggedIn ||
            <Container fluid className={"p-0"}>
                    <div className={"home-splash d-flex align-items-end"} style={backgroundStyle}>
                        <div className={"text-center p-3"} style={{width:"100%"}}>
                        <h3 className={"text-shadow"} style={{color:"white",fontSize:"45px",fontWeight:"bolder"}}>Wеlсоmе tо {Site.name}</h3>
                        <p className={"text-shadow"} style={{color:"white",fontSize:"24px",}}>{Site.siteIntroMessage}</p>
                        <a href={"#welcome"} className={"btn btn-primary"}>Read more</a>
                        </div>
                    </div>

                </Container>
            }
            <Container className={"mt-5 text-center"}>
                <Row>
                    <Col>
                        {
                            applicationState.complete === 0 &&
                            <React.Fragment>
                                <h1>Ready to start your application</h1>
                                <p>

                                    Make sure you answer all of the required questions and provide a phone number and
                                    email address for us to contact you.<br/>Read through the <a
                                    href={AdminUrls.TERMS}>T&Cs</a> and then accept them to
                                    confirm you understand.
                                </p>
                                <div>

                                    {
                                        !isLoggedIn &&
                                        <a href="/register" className="btn btn-cta btn-outline-primary">Start my application
                                            now</a>
                                    }
                                    {
                                        isLoggedIn &&
                                        <a href="/application" className="btn btn-cta btn-outline-primary">Continue with
                                            application</a>
                                    }
                                </div>
                            </React.Fragment>
                        }
                        {
                            applicationState.complete === 1 && <React.Fragment>
                                <h1>Welcome to {Site.name}!</h1>
                            </React.Fragment>
                        }
                    </Col>

                </Row>
            </Container>
            {isLoggedIn || Site.clientId==="ukare"?<UkareHomeContent/>: <CariCareHomeContent/>
            }
        </ApplicantLayout>
    );
};
export default Home;