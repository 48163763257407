import React, {useEffect, useState} from "react";
import {Col, Container, Row ,Table} from "react-bootstrap";
import AdminLayout from "../../../components/admin/AdminLayout";
import {getCurrentUser, getUserRoleDescription, UserRoles} from "../../../services/user";
import {getUsersByRoles} from "../../../api/user";
import {AdminUrls} from "../../../urls";
import {WithUserRoles} from "../../../components/admin/WithUserRoles";
import WithProgressBar from "../../../components/WithProgressBar";

const AdminUsers = ({setLoading,setLoadingPercentage,isLoading}) => {

const currentUser = getCurrentUser();
const [users, setUsers] = useState([]);
useEffect(()=>{

    setLoading(true);
    getUsersByRoles([UserRoles.ADMIN],setLoadingPercentage).then(data=>{
        setUsers(data);
        setLoading(false);


    }).catch(message=>{


    })

},[])
    const activeUsers = users.filter(user => user.active)


    return <AdminLayout isLoading={isLoading}>
        <Container className={"mb-3"}>
            <Row>
                <Col>
                    <h1>Manage Staff</h1>
                </Col>
            </Row>
        </Container>
        <Container >
            {(currentUser.roles.includes(UserRoles.OWNER) || currentUser.roles.includes(UserRoles.USER_MANAGEMENT))&&
                <Row>
                    <Col className={"text-right mb-3"}><a href={AdminUrls.ADMIN_ADD_USER}>Add Admin user</a></Col>
                </Row>
            }
            <Row>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Full name</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>{""}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        activeUsers.map((user,index)=><tr key={index}><td>{user.firstName} {user.lastName}</td><td>{user.email}</td><td>{user.roles.filter(role => [UserRoles.OWNER,UserRoles.USER_MANAGEMENT,UserRoles.ADMIN].includes(role)).map(role=> getUserRoleDescription(role)+" , ")}</td><td>< a href={AdminUrls.ADMIN_EDIT_USER+user.id} >Edit</a></td></tr>)

                    }
                    </tbody>
                </Table>

            </Row>

        </Container>

    </AdminLayout>

};
export  default  WithUserRoles(WithProgressBar(AdminUsers), [UserRoles.USER_MANAGEMENT, UserRoles.OWNER]);