import { Switch, Route ,Redirect } from 'react-router-dom'
import React, {Fragment} from "react";
import Registration from "../pages/Registration";
import Home from "../pages/Home";
import { useSelector} from 'react-redux'
import Login from "../pages/Login";
import ValidationEmail from "../pages/ValidationEmail";
import Terms from "../pages/Terms";
import Application from "../pages/Application";
import Recover from "../pages/Recover";
import RecoverCode from "../pages/RecoverCode";
import AdminUsers from "../pages/admin/admin-users/AdminUsers"
import AddUser from "../pages/admin/admin-users/AddUser";
import Applicants from "../pages/admin/Applicants";
import {AdminUrls} from "../urls";
import Applicant from "../pages/admin/Applicant";
import Privacy from "../pages/Privacy";
import About from "../pages/About";
import Contact from "../pages/Contact";
import CookiesPolicy from "../pages/CookiesPoliciy";
import LegalNotice from "../pages/LegalNotice";
const Main = ()=> {
    const user = useSelector(state => state.user);

    let notLoggedInRoutes = [
                <Route key="r" exact path='/register' component={Registration}/>,
                <Route key="l" exact path='/login' component={Login}/>,
                <Route key="rc" exact path="/recover/code/:code" component={RecoverCode} />,
                <Route key="rr" exact path="/recover" component={Recover} />
          ];

    let loggedInRoutes = [
        <Route key="ap" exact path='/application' component={Application}/>,


    ];

    if( user.isLoggedIn && user.roles.includes("ADMIN")) {
        let adminRoutes = [
            <Route key="h" exact path='/' component={Applicants}/>,
            <Route key="adminApplications" exact path='/admin/applicants' component={Applicants}/>,
            <Route key="adminUsers" exact path='/admin/users' component={AdminUsers}/>,
            <Route key="adminAddUser2" exact path='/admin/users/add' component={AddUser}/>,
            <Route key="adminEditUser3" exact path='/admin/users/edit/:userId' component={AddUser}/>,
            <Route key="adminEditUser4" exact path={(AdminUrls.ADMIN_APPLICANT+':applicantId')} component={Applicant}/>
        ];
       loggedInRoutes = [adminRoutes, ...loggedInRoutes] ;

    }

    let eitherRoutes = [

        <Route key="h" exact path='/' component={Home}/>,
        <Route key="tc" exact path={AdminUrls.TERMS} component={Terms}/>,
        <Route key="tc1" exact path={AdminUrls.PRIVACY} component={Privacy}/>,
        <Route key="tc2" exact path={AdminUrls.ABOUT} component={About}/>,
        <Route key="tc3" exact path={AdminUrls.CONTACT} component={Contact}/>,
        <Route key="tc31" exact path={AdminUrls.COOKIES} component={CookiesPolicy}/>,
        <Route key="tc323" exact path={AdminUrls.LEGAL_NOTICE} component={LegalNotice}/>,
        <Route key="vmm"  exact path="/verify/email/:code"  component={ValidationEmail}/>
    ];


    return (
        <main>
            <Switch>

                {

                    user.isLoggedIn ? loggedInRoutes.map((route) => {
                        return route;
                    }) : notLoggedInRoutes.map((route) => {
                        return route;
                    })
                }

                {
                    eitherRoutes.map((route) => {
                        return route;
                    })
                }


                <Redirect to="/" />
            </Switch>
        </main>
    );
};
export default Main;