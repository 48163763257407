import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import LoginForm from "../components/LoginForm";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
const Login  = ()=> {
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1>Please sign in</h1>
                        <LoginForm />

                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};
export default Login;