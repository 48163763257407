import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {
    useParams
} from "react-router-dom";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {useSelector} from "react-redux";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";


const ValidationEmail  = ()=> {

    let{ code } = useParams();
    const [message, setMessage] = useState("Verifying your email using code "+code);
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const [emailVerified , setEmailVerified] = useState(false);


    useEffect(()=>{

        let data ={appId:Site.clientId,code :code};
        axios.post(  API_ROOT_URL +"/verify/email/",  data )
            .then(res => {
                console.log(res);
                setEmailVerified(true);
                 setMessage("Email verified, thank you");


            }).catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                if(error.response.status === 404)
                {
                    setMessage("Invalid verification code");

                }
                else {

                }

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    },[]);

    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <p>{message}</p>



                        {
                          emailVerified && !isLoggedIn &&
                            <a href="/login" className="btn btn-cta btn-primary">Start my application now</a>
                        }
                        {
                           emailVerified && isLoggedIn &&
                            <a href="/application" className="btn btn-cta btn-primary">Continue with application</a>
                        }

                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};
export default ValidationEmail;