import React, {useState} from 'react';
import Progress from "../components/Progress";

function WithProgressBar(Component) {
    return function WithProgressBarComponent({ ...props }) {

        const [isLoading , setLoading] = useState(false);
        const [percentage ,setPercentage] = useState(0);


        return (
            <>
                {isLoading && <Progress percentage={percentage}/>
                }
                <Component {...props} setLoading={(loading)=>setLoading(loading)} setLoadingPercentage={(perc)=>setPercentage(perc)} isLoading={isLoading}/>
        </>
        );
    };
}
export default WithProgressBar;