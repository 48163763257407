import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {localStorageService} from "../services/localStorgeService";

const CookieConsent = ()=>{
const [agreed, setAgreed] = useState(localStorageService.getItem("conset") != null);
    const OnAgree = ()=>{

        localStorageService.setItem("conset", true);
        setAgreed(true);
    };
if(agreed) return null;
    return(
        <div style={{width:"100%", bottom:0, backgroundColor:"white", position:"fixed",padding:"20px",textAlign:"center"}}>
            <p>This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish. <a href="terms-and-conditions">Read more</a> </p>
            <Button onClick={OnAgree}>I agree</Button>

        </div>
    );
};
export default  CookieConsent;