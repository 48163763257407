import React from "react";
import {getCurrentUser} from "../../services/user";
import {Redirect} from "react-router-dom";
import {AdminUrls} from "../../urls";

export function WithUserRoles(BaseComponent , roles) {
    return function WithUserRolesComponent(props) {
         const currentUser = getCurrentUser();

         let matches = roles.filter(role=>{

             return currentUser.roles.includes(role);
         })

        // need to match only one role to get through
        if(matches.length > 0 )
        {
            return <BaseComponent {...props} />
        }
        /*

        if(matches.length === roles.length) {
            return <BaseComponent {...props} />
        }
        */

        return <Redirect to={AdminUrls.ADMIN_USERS} />;

    }
}