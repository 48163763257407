import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Alert, Button, Form} from "react-bootstrap";
import { updateApplicationStatus} from "../actions";
import {useDispatch ,useSelector} from "react-redux";
import {API_ROOT_URL} from "../urls";
import {countryList} from "../util/CountryList";
import {removeTimeFromDateTime} from "../util/Date";
import Progress from "./Progress";
import Site from "../util/Site";



const nursingChoices =()=>
{
    return  ["Neonatal Nurse","Nurse Midwife","Clinical Nurse","Critical Care Nurse","Dialysis Nurse","Nurse Practitioner","Informatics Nurse", "Nurse Anesthetist","Nurse Educator","Nurse Advocate","Nurse Researcher","Pain Management Nurse","Psychiatric Nurse","Trauma Nurse","Travel Nurse","Pediatric Nurse"," Geriatric Nurse","Public Health Nurse","Oncology Nurse"];

};

const  otherChoices=()=>
{
    return ["Allied health professionals","Dental Team","Doctors","Midwife","Nursing","Pharmacy"];

};


const EmploymentForm =()=>{

    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const currentUser = useSelector(state => state.user);
    const [validated, setValidated]  = useState(false);
    const [error, setError]  = useState("");
    const [inputs, setInputs] = useState({specialityOfType : "nursing"});
    const applicationState = useSelector(state =>state.applicationStatus);
    const dispatch = useDispatch();
    const handleInputChange=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputs(inputs => ({...inputs, [name]: value}));



    };
    useEffect(()=>{

        let data ={appId:Site.clientId,accessToken:currentUser.accessToken};
        setShowProgress(true);
        //alert(JSON.stringify(data)); return;
        axios.post(  API_ROOT_URL +"/application/",  data,{
            onUploadProgress: progressEvent => {
            setUploadPercentage(
                parseInt(
                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
            );
        }} )
            .then(res => {
                setShowProgress(false);
                let newInputs = {...res.data};
                newInputs.dateOfQualification = removeTimeFromDateTime( newInputs.dateOfQualification);
                setInputs(newInputs);
                setValidated(true);
                // console.log(res.data);
                let newApplicationState = {...applicationState};
                newApplicationState.employment = 1;

                dispatch(updateApplicationStatus(newApplicationState));

            }).catch((error) => {
            setShowProgress(false);
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                if(error.response.status === 404)
                {
                    //setError("No application info")

                }
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    },[]);

    const handleSubmit = (event)=>
    {
        event.preventDefault();




        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            let data ={...inputs,appId:Site.clientId,accessToken:currentUser.accessToken};
            //alert(JSON.stringify(data)); return;
            setShowProgress(true);
            axios.post(  API_ROOT_URL +"/application/update/",  data )
                .then(res => {
                    console.log(res);
                    // console.log(res.data);
                    //go to the next page
                    setShowProgress(false);

                    let newApplicationState = {...applicationState};
                    newApplicationState.employment = 1;
                    dispatch(updateApplicationStatus(newApplicationState));


                }).catch((error) => {
                setShowProgress(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if(error.response.status === 404)
                    {
                        setError("Invalid email ID or password used")

                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
        setValidated(true);



    };

    let specialityList = otherChoices();
    if(inputs.specialityOfType === "nursing")
    {
        specialityList = nursingChoices();
    }

    if(showProgress)
    {
        return  (<Progress percentage={uploadPercentage} />);

    }

    return (

        <div>
            {error !=="" &&
            <Alert key="errormessage" variant="danger">
                {error}
            </Alert>
            }
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Group  controlId="countryOfQualification">
                    <Form.Label>Country of qualification</Form.Label>
                    <Form.Control as="select"
                                  required
                                  defaultValue={inputs.countryOfQualification}
                                  onChange={handleInputChange}
                                  name="countryOfQualification"
                                  placeholder="choose"


                    >
                        <option  value="">Choose...</option>
                        {countryList.map((country)=>{
                            if(inputs.countryOfQualification === country)
                                return <option key={country} value={country} selected>{country} </option>;
                            else return <option key={country} value={country}>{country} </option>
                        })}


                    </Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group   controlId="dateOfQualification">
                    <Form.Label>Date of qualification</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        name="dateOfQualification"
                        defaultValue={inputs.dateOfQualification}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group  controlId="workLocation">
                    <Form.Label>Work location</Form.Label>
                    <Form.Control as="select"
                                  required
                                  onChange={handleInputChange}
                                  name="workLocation"
                                  placeholder="choose"


                    >
                        <option  value="">Choose...</option>
                        {countryList.map((country)=>{
                            if(inputs.workLocation === country)
                            return <option key={country} value={country} selected>{country} </option>;
                            else return <option key={country} value={country}>{country} </option>
                        })}
                    </Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group   controlId="clinicalExperience">
                    <Form.Label>Clinical experience</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="3"
                        required
                        name="clinicalExperience"
                        defaultValue={inputs.clinicalExperience}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group   controlId="specialityOfType">
                    <Form.Label>Specialty</Form.Label>
                    <br/>

                    <Form.Check
                        style={{display:"inline-block"}}
                        type="radio"
                        label="Nursing"
                        name="specialityOfType"
                        defaultValue="nursing"
                        defaultChecked={inputs.specialityOfType === "nursing"}
                        onChange={handleInputChange}
                    />
                    <Form.Check
                        className="ml-3"
                        style={{display:"inline-block"}}
                        type="radio"
                        label="Other"
                        name="specialityOfType"
                        defaultValue="other"
                        defaultChecked={inputs.specialityOfType==="other"}
                        onChange={handleInputChange}
                    />

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>



                <Form.Group  controlId="speciality">
                    <Form.Label>Please specify</Form.Label>
                    <Form.Control as="select"
                                  required
                                  onChange={handleInputChange}
                                  name="speciality"
                                  placeholder="choose"


                    >
                        <option  value="">Choose...</option>
                        {specialityList.map((name)=>{
                            if(inputs.speciality === name)
                            return <option key={name} value={name} selected>{name}</option>;
                            else return <option key={name} value={name}>{name}</option>
                        })}


                    </Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>


                <Form.Group   controlId="additionalInformation">
                    <Form.Label>Additional information</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="3"
                        name="additionalInformation"
                        defaultValue={inputs.additionalInformation}
                        onChange={handleInputChange}
                    />
                </Form.Group>



                <Button className="btn-cta" type="submit">Next</Button>
            </Form>
        </div>
    );

};



export default EmploymentForm;