import React from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";

import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
import CompanyInfo from "../components/CompanyInfo";


const LegalNotice = () => {
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1 dir="ltr">Legal notice</h1>
                        <h5>Owner
                        </h5>
                        <CompanyInfo/>
                        <br/>
                        <h5>Contents of the online offer</h5>
                        <p>
                            The contents of this website are created with the greatest possible care. The author accepts no responsibility for the topicality, correctness, completeness or quality of the information provided. Liability claims against the author, which refer to material or non-material damages, which have been caused by the use or non-use of the information provided or by the use of incorrect and incomplete information, are fundamentally excluded, provided that there is no demonstrable intentional or grossly negligent fault on the part of the author. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without separate announcement or to discontinue the publication temporarily or permanently.

                        </p>
                        <h5>
                            References and links
                        </h5>
                        <p>
                            In the case of direct or indirect references to external Internet pages ("links"), which are outside the author's area of responsibility, a liability obligation would only come into force in the event that the author is aware of the contents and it would be technically possible and reasonable for him to prevent use in the event of illegal contents. The author hereby expressly declares that at the time the links were created, no illegal content was discernible on the linked pages. The author has no influence on the current and future design, content or authorship of the linked pages. Therefore, he hereby expressly distances himself from all contents of all linked pages that were changed after the link was set. This statement applies to all links and references set within the author's own Internet offer, as well as to external entries in discussion forums and mailing lists set up by the author. For illegal, incorrect or incomplete contents and in particular for damages resulting from the use or non-use of such information, the provider of the page to which reference is made shall be solely liable, not the person who merely refers to the respective publication via links.

                        </p>
                        <h5>
                            Data protection
                        </h5>
                        <p>
                            If the opportunity for the input of personal or business data (email addresses, name, addresses) is given, the input of these data takes place voluntarily. Further information is available in our privacy and cookie policy.
                        </p>
                        <h5>
                            Validity of this disclaimer
                        </h5>
                        <p>
                            This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.
                        </p>
                        <h5>
                            Copyright
                        </h5>
                        <p>
                            For the website design, the text, all graphics and photos, each selection or layout thereof: Copyright © 2020 {Site.companyName}. Copying or reproduction of the entire website or parts of this website are not permitted. Any other use of the materials or information available on this website - including reproduction, further distribution, modification, and publication for any purpose is prohibited, unless {Site.companyName} has given its prior written consent.
                        </p>
                        <h5>
                            Online dispute resolution according to Art. 14 para. 1 ODR-VO:
                        </h5>
                        <p>
                            The European Commission provides an Online Dispute Resolution (OS) platform, which can be found at http://ec.europa.eu/consumers/odr/ We are prepared to participate in an out-of-court conciliation procedure before a consumer dispute resolution body.
                        </p>
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>);

};
export default LegalNotice ;