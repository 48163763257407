import React from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";

import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
import CompanyInfo from "../components/CompanyInfo";


const CookiesPolicy = () => {
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1 dir="ltr">Cookie Policy</h1>
                        <h5 dir="ltr">What is a cookie?</h5>
                        <p dir="ltr">Cookies are small pieces of data, stored in text files, that are stored on your
                            computer or other device when websites are loaded in a browser. They are widely used
                            to &ldquo;remember&rdquo; you and your preferences, either for a single visit (through
                            a &ldquo;session cookie&rdquo;) or for multiple repeat visits (using a &ldquo;persistent
                            cookie&rdquo;). They ensure a consistent and efficient experience for visitors, and perform
                            essential functions such as allowing users to register and remain logged in. Cookies may be
                            set by the site that you are visiting (known as &ldquo;first party cookies&rdquo;), or by
                            third parties, such as those who serve content or provide advertising or analytics services
                            on the website (&ldquo;third party cookies&rdquo;).</p>
                        <p dir="ltr">Both websites and HTML emails may also contain other tracking technologies such
                            as &ldquo;web beacons&rdquo; or &ldquo;pixels.&rdquo; These are typically small transparent
                            images that provide us with statistics, for similar purposes as cookies. They are often used
                            in conjunction with cookies, though they are not stored on your computer in the same way. As
                            a result, if you disable cookies, web beacons may still load, but their functionality will
                            be restricted. </p>
                        <h5 dir="ltr">Advertising technologies</h5>
                        <p dir="ltr">Our website uses the below technologies for advertising purposes. Those are modern,
                            advanced analysis methods and tools to understand users&rsquo; habits on our website. This
                            information helps us improve our products and services and provide relevant services and
                            information. In principle, we use only anonymized data that does not allow us to identify
                            the user.</p>
                        <p dir="ltr">Below we describe the analysis services and technology that we use for such
                            purposes. Additionally, we show how you can prevent these services from analysis of your use
                            of our website.</p>
                        <h5 dir="ltr">Google Analytics</h5>
                        <p dir="ltr">We use Google Analytics to help us understand how you engage with our site so that
                            we can improve it. Google Analytics reports website trends without identifying individual
                            visitors. You may install a Google Analytics Opt-Out Browser Button <a
                                href="https://tools.google.com/dlpage/gaoptout">here</a>. The Google Analytics Privacy
                            Policy can be read <a href="https://policies.google.com/privacy?hl=en-US">here</a>.</p>
                        <p dir="ltr">Facebook Connect</p>
                        <p dir="ltr">Facebook Connect is a Facebook tool that allows Facebook users to log into other
                            websites, apps and services using their Facebook account. Thus, the other service gains
                            access to some of the preferences of the user&rsquo;s Facebook account and social
                            connections on which it can base its own products and services. This is dependent on the
                            user&rsquo;s Facebook settings. More details are available in the privacy policy of
                            Facebook. Facebook may use data on how users use such other services in order to recognize
                            visitors to our website on its services, which enables it to tailor its advertisements to
                            the interests and preferences of the user. You can opt out of this form of advertising by
                            changing the settings at the Facebook settings page.</p>
                        <p dir="ltr">Facebook Custom Audiences</p>
                        <p dir="ltr">Facebook Custom Audiences enables us to use existing datasets to compile groups and
                            lists of users based on certain criteria in order to provide users with information and
                            advertisements, tailored to their interests and preferences, on Facebook&rsquo;s services.
                            Before transmission to Facebook, the data is irreversibly encrypted in the form of hash
                            values on our computers; Facebook can compare these against its user databases. You can opt
                            out of this form of advertising by changing the settings at the Digital Advertising Alliance
                            page.</p>
                        <p dir="ltr">Facebook Lookalike Audiences</p>
                        <p dir="ltr">Facebook Lookalike Audiences enables us to use existing datasets to compile groups
                            and lists of users based on certain criteria in order to provide users with similar
                            interests and preferences with relevant information on Facebook&rsquo;s services. Before
                            transmission to Facebook, the data is irreversibly encrypted in the form of hash values
                            (encrypted numeric values) on our computers. You can opt out of this form of advertising by
                            changing the settings on the <a href="https://digitaladvertisingalliance.org/">Digital
                                Advertising Alliance</a> page.</p>
                        <p dir="ltr">Twitter advertising</p>
                        <p dir="ltr">Twitter provides advertising services that use cookies, mobile device IDs or hash
                            values for email addresses in order to recognize visitors to our website on its services and
                            provide them with advertisements tailored to their interests and preferences. These measures
                            can be limited or deactivated by changing the settings of your Twitter account</p>
                        <p dir="ltr">Instagram advertising</p>
                        <p dir="ltr">Instagram provides advertising services that use cookies, mobile device IDs or hash
                            values for email addresses in order to recognize visitors to our website on its services and
                            provide them with advertisements tailored to their interests and preferences. These measures
                            can be limited or deactivated by changing the settings of your Instagram account.</p>
                        <p dir="ltr">Social Media Widgets</p>
                        <p dir="ltr">We display sharing buttons so you can use social networking to share items from our
                            site. It also includes the recommend buttons and other interactive programs that run on our
                            site that collect your IP address, which page you are visiting on our site, and sets a
                            cookie to enable the widget to function properly. Your interactions with these Widgets are
                            governed by the privacy policy of the company providing them.</p>
                        <h5 dir="ltr">How we use cookies</h5>
                        <p dir="ltr">We use cookies for a number of different purposes. Some cookies are necessary for
                            technical reasons; some enable a personalized experience for both visitors and registered
                            users; and some allow the display of advertising from selected third-party networks. Some of
                            these cookies may be set when a page is loaded, or when a visitor takes a particular action
                            (clicking the &ldquo;like&rdquo; or &ldquo;follow&rdquo; button on a post, for example).</p>
                        <h5 dir="ltr">Types of Cookie</h5>
                        <p dir="ltr">The table below explains the types of cookies we use on our websites and why we use
                            them.</p>
                        <div dir="ltr" align="left">
                            <table>
                                <tbody>
                                <tr>
                                    <td><p dir="ltr">Category of cookies </p></td>
                                    <td><p dir="ltr">Why we use these cookies</p></td>
                                </tr>
                                <tr>
                                    <td><p dir="ltr">Functionality</p></td>
                                    <td><p dir="ltr">These cookies are used to store preferences set by users such as
                                        language, and location.</p></td>
                                </tr>
                                <tr>
                                    <td><p dir="ltr">Security</p></td>
                                    <td><p dir="ltr">We use these cookies to help identify and prevent potential
                                        security risks.</p></td>
                                </tr>
                                <tr>
                                    <td><p dir="ltr">Analytics and Performance </p></td>
                                    <td><p dir="ltr">Performance cookies collect information on how users interact with
                                        our website, as well as other analytical data. We use these details to improve
                                        how our website functions and to understand how users interact with it.</p></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 dir="ltr">Changes </h5>
                        <p dir="ltr">This Cookie Policy and our commitment to protecting the privacy of your personal
                            data can result in changes to this Cookie Policy. Please regularly review this Cookie Policy
                            to keep up to date with any changes.</p>
                        <h5 dir="ltr">Queries and Complaints </h5>
                        <p dir="ltr">Any comments or queries on this policy should be directed to us using the following
                            contact details.</p>
                        <div className={"text-left"}>
                            <CompanyInfo/>
                        </div>
                        <br/>
                        <p dir="ltr">If you believe that we have not complied with this policy or acted otherwise than
                            in accordance with data protection law, then you should notify us. </p>


                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>);

};
export default CookiesPolicy;