import React, {useState} from "react";
import {Col, Container, Row, Form, Alert} from "react-bootstrap";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import UserRegistrationForm from "../components/UserRegistrationForm";
import TermsFormCheck from "../components/TermsFormCheck";

import {loginUser} from "../actions";
import {useDispatch} from "react-redux";
import Progress from "../components/Progress";
import {registerUser} from "../api/user";

const Registration = () => {

    const [error, setError] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [isFormValidated, setFormValidated] = useState(false);
    const dispatch = useDispatch();
    const OnSubmit = (inputs) => {

        setShowProgress(true);
        let data = {...inputs, acceptedTerms: true};
        registerUser(data, progressPercentage => {
            setUploadPercentage(progressPercentage);
        }).then(res => {
            setShowProgress(false);
            dispatch(loginUser(res.data.accessToken, inputs.firstName, []));

        }).catch((message) => {
            setShowProgress(false);
            setError(message);

        });

        setFormValidated(true);
    }
    const onSubmitFailure = (inputs) => {

        setFormValidated(true);
    }
    if (showProgress) {
        return (<Progress percentage={uploadPercentage}/>);

    }
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1>Register</h1>
                        {
                            error &&
                            <Alert key="errormessage" variant="danger">
                                {error}
                            </Alert>
                        }
                        <UserRegistrationForm onSubmit={OnSubmit} onSubmitFailure={onSubmitFailure}
                                              submitButtonTitle={"Register now"}>
                            <Form.Group>
                                <TermsFormCheck validated={isFormValidated}/>
                            </Form.Group>
                        </UserRegistrationForm>
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};
export default Registration;