import {Alert, Button, Form} from "react-bootstrap";
import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {useDispatch, useSelector} from "react-redux";
import FilesGroup from "./FilesGroup";
import {
    File,
    FILE_TYPE_CERTIFICATE,
    FILE_TYPE_CV,
    FILE_TYPE_DR_LETTERS,
    FILE_TYPE_ID,
    FILE_TYPE_POLICE_CHECKS, FILE_TYPE_PROFILE_PICTURE
} from "../entities/File";
import {updateApplicationStatus} from "../actions";
import Progress from "./Progress";
import Site from "../util/Site";


const FilesForm =()=>{

    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const currentUser = useSelector(state => state.user);
    const applicationState = useSelector(state =>state.applicationStatus);
    const dispatch = useDispatch();
    const [validated, setValidated]  = useState(false);
    const [error, setError]  = useState("");
    const [files, setFiles] = useState(
        [
        new File(FILE_TYPE_ID),
            new File(FILE_TYPE_CERTIFICATE),
        new File(FILE_TYPE_CV),
        new File(FILE_TYPE_DR_LETTERS),
        new File(FILE_TYPE_POLICE_CHECKS),
        new File(FILE_TYPE_PROFILE_PICTURE)]);

    const handleInputChange=(event)=>{
        const target = event.target;
//{profilePictureInput:{filename:"Choose file"}}

            let newFiles = [...files];
            for (let i = 0; i < newFiles.length; i++)
            {
                let file = newFiles[i];
                if(file.name === event.target.name  )
                {
                    file.file = target.files[0];
                    file.filename = target.files[0].name;
                    //newFiles[i] = file;

                }
            }

            setFiles(newFiles);




    };

    const handleSubmit = (event)=>
    {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            let jsonData ={appId:Site.clientId,accessToken:currentUser.accessToken};


            let jsonArray = [];
            for (let i = 0; i < files.length; i++) {

                //
                if(files[i].file !== undefined && files[i].file !==null )
                jsonArray.push(files[i]);
            }
            jsonData={...jsonData,payloads:jsonArray};

            const json = JSON.stringify(jsonData );
            //const blob = new Blob([json], {
           //     type: 'application/json'
           // });
            //alert(json);
            const data = new FormData();
            data.append("document", json);

            // put the files
            for (let i = 0; i < files.length; i++) {
                if(files[i].file !== undefined && files[i].file !==null )
                data.append(files[i].name, files[i].file);
            }

            setShowProgress(true);
            axios.post(  API_ROOT_URL +"/files/upload/",  data ,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                ,
                onUploadProgress: progressEvent => {
                setUploadPercentage(
                    parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    )
                );
            }
            } )
                .then(res => {
                    //console.log(res);
                    // console.log(res.data);
                    //go to the next page
                    setShowProgress(false);

                    let newApplicationState = {...applicationState};
                    newApplicationState.files= 1;
                    dispatch(updateApplicationStatus(newApplicationState));


                }).catch((error) => {
                    setShowProgress(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if(error.response.status === 404)
                    {
                       // setError("Invalid email ID or password used")

                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */

                } else {
                    // Something happened in setting up the request and triggered an Error

                }

            });
        }
        setValidated(true);


    };

    const addFileToGroup=(fileType)=>{
        let newFile = new File(fileType);
        let newFiles = [...files];
        newFiles.push(newFile);
        setFiles(newFiles);

    };

    if(showProgress)
    {
        return  (<Progress percentage={uploadPercentage} />);

    }

    return ( <div>
        {error !=="" &&
        <Alert key="errormessage" variant="danger">
            {error}
        </Alert>
        }

        <Form noValidate validated={validated} onSubmit={handleSubmit}>

            {uploadPercentage === 0 && <React.Fragment>
                <FilesGroup key="cvs" onChange={handleInputChange} fileType={FILE_TYPE_PROFILE_PICTURE} files={files}
                            title="Profile photo" addFileInGroup={addFileToGroup} maxCount={1}/>
                < FilesGroup key="rere" onChange={handleInputChange} fileType={FILE_TYPE_ID} files={files} title="Profile Ids (passport , driving license)"  addFileInGroup={addFileToGroup} maxCount={4} />
                <FilesGroup key="crr" onChange={handleInputChange} fileType={FILE_TYPE_CV} files={files} title="Attach CV Resume"  addFileInGroup={addFileToGroup} maxCount={4} />
                <FilesGroup key="cxx" onChange={handleInputChange} fileType={FILE_TYPE_CERTIFICATE} files={files} title="Certificates"  addFileInGroup={addFileToGroup} maxCount={4} />
                <FilesGroup key="cer" onChange={handleInputChange} fileType={FILE_TYPE_DR_LETTERS} files={files} title="Dr letters"  addFileInGroup={addFileToGroup} maxCount={4} />
                <FilesGroup key="cww" onChange={handleInputChange} fileType={FILE_TYPE_POLICE_CHECKS} files={files} title="Police checks"  addFileInGroup={addFileToGroup} maxCount={4} />


                <Button className="btn-cta" type="submit">Next</Button>
            </React.Fragment>

            }
        </Form>
    </div>);

};
export default FilesForm;