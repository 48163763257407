import React from 'react';
import {Nav, Navbar} from "react-bootstrap";
import { useSelector,useDispatch} from 'react-redux'
import Site from "../util/Site";
import {logOutAction} from "../actions";
import {AdminUrls} from "../urls";
import {UserRoles} from "../services/user";


const  Header =(props)=>{

    const user = useSelector(state => state.user);
    const dispatcher = useDispatch();



    let navList = [];

    if(user.isLoggedIn)
    {
        if(user.roles.includes("ADMIN")) {
            navList.push(<Nav.Link key="admin_applications" href="/admin/applicants">Applicants</Nav.Link>);
            if(user.roles.includes(UserRoles.OWNER) || user.roles.includes(UserRoles.USER_MANAGEMENT))
                navList.push(<Nav.Link key="admin_users" href="/admin/users">Staff</Nav.Link>);


        }
        else {

            navList.push(<Nav.Link key="account" href="/application">Application</Nav.Link>);
        }
        navList.push(<Nav.Link key="about_us" href={AdminUrls.ABOUT}>About us</Nav.Link>);
        navList.push(<Nav.Link key="contact_us" href={AdminUrls.CONTACT}>Contact us</Nav.Link>);
        navList.push(<Nav.Link key="logout" onClick={()=>dispatcher(logOutAction())} >Log out</Nav.Link>);

    }

    else {
        navList.push(<Nav.Link key="about_us" href={AdminUrls.ABOUT}>About us</Nav.Link>);
        navList.push(<Nav.Link key="contact_us" href={AdminUrls.CONTACT}>Contact us</Nav.Link>);
        navList.push(<Nav.Link key="login" href="/login">Login</Nav.Link>);
        navList.push(<Nav.Link key="register" className="sign-up" href="/register">Sign up now</Nav.Link>);
    }

        return (
        <header>
            <Navbar  expand="lg">
                <Navbar.Brand href="/"><img src={Site.logo} alt={"company logo"}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {navList.map((navLink)=>{return navLink;})}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </header>);
    
};

export default Header;