import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import CookieConsent from "../CookieConsent";
const AdminLayout = ({children,isLoading}) =>{


    if(isLoading)
        return null;

    return <>
    <Header/>
        {
            children
        }

    <Footer/>
    <CookieConsent/>
    </>

}
export default AdminLayout;