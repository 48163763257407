import React, {useState} from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {
    useParams
} from "react-router-dom";
import Progress from "../components/Progress";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
const RecoverCode  = ()=> {


    let{ code } = useParams();
    const [validated, setValidated]  = useState(false);
    const [passwordUpdated , setPasswordUpdated] = useState(false);
    const [codeNotFound,setCodeNotFound] = useState(false);

    const [inputs, setInputs] = useState({});
    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const dispatch = useDispatch();
    const handleInputChange=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputs(inputs => ({...inputs, [name]: value}));

    };

    const handleSubmit = (event)=>
    {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            setShowProgress(true);
            let data ={...inputs,appId:Site.clientId,code: code};
            axios.post(  API_ROOT_URL +"/user/update/password/",  data ,{
                onUploadProgress: progressEvent => {
                    setUploadPercentage(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
                }
            })
                .then(res => {

                    setPasswordUpdated(true);
                    setShowProgress(false);
                    // console.log(res.data);


                }).catch((error) => {
                setShowProgress(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if(error.response.status === 404)
                    {

                        setCodeNotFound(true);

                    }

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
        setValidated(true);


    };

    if(showProgress)
    {
        return  (<Progress percentage={uploadPercentage} />);

    }


    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        {
                            !passwordUpdated &&
                            <React.Fragment>
                                <h1>Please enter your new password</h1>
                                {
                                    codeNotFound &&  <Alert key="errormessage" variant="danger">
                                        You have used an invalid code
                                    </Alert>
                                }

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group    controlId="newPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            placeholder="******"
                                            name="newPassword"
                                            defaultValue={inputs.newPassword}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                    <Button className="btn-cta" type="submit">Update password</Button>
                                </Form>
                            </React.Fragment>
                        }

                        {
                            passwordUpdated && <React.Fragment>
                                <h1>Password updated</h1>
                                <p>
                                    You can login now using the new password

                                </p>

                            </React.Fragment>
                        }


                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};
export default RecoverCode;