import React, {useEffect, useState} from "react";
import {Col, Container, Row, Table, Card, Button} from "react-bootstrap";
import {Link, Redirect, useParams} from "react-router-dom";
import {updateApplicantStatus, getApplicant, ApplicantStatus} from "../../api/applicant";
import AdminLayout from "../../components/admin/AdminLayout";
import {AdminUrls, API_ROOT_URL} from "../../urls";
import {WithUserRoles} from "../../components/admin/WithUserRoles";
import WithProgressBar from "../../components/WithProgressBar";
import Site from "../../util/Site";
import {removeTimeFromDateTime} from "../../util/Date";
import {getCurrentUser, UserRoles} from "../../services/user";
import {editUser} from "../../api/user";

const DeleteConfirm = ({applicant, onDeleted}) => {

    const [isConfirmDelete, setConfirmDelete] = useState(false);


    const deleteApplicant = () => {

        if (isConfirmDelete) {

            const deActivatedApplicant = {...applicant, active: false};
            editUser(deActivatedApplicant, progressPercentage => {

            }).then(res => {

                setConfirmDelete(false);
                onDeleted(deActivatedApplicant);


            }).catch((message) => {

                alert("Failed to delete applicant")

            });
        } else {
            setConfirmDelete(true);
        }


    }


    return (

        <Row>
            <Col className={"text-right"}>
                {isConfirmDelete &&
                <Button className={"m-1"} variant="primary" onClick={() => {
                    setConfirmDelete(false)
                }}>Cancel delete </Button>}
                <Button className={"m-1"} variant={isConfirmDelete ?"danger":"outline-danger"}
                        onClick={() => deleteApplicant()}>{isConfirmDelete ? "Confirm delete" : "Delete user"} </Button>

            </Col>
        </Row>
    );


}

const Applicant = ({setLoading, setLoadingPercentage, isLoading}) => {

    let {applicantId} = useParams();
    const [applicant, setApplicant] = useState({});
    const [application, setApplication] = useState({});
    const [files, setFiles] = useState([]);
    const [applicantStatus, setApplicantStatus] = useState(null);
    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isCurrentUserOwner , setCurrentUserIsOwner ] = useState(false);


    useEffect(() => {

        setCurrentUserIsOwner(getCurrentUser().roles.includes(UserRoles.OWNER));

        setLoading(true);
        getApplicant(applicantId, setLoadingPercentage).then(data => {

            // if(mounted) {
            let applicantData = data[0];
            setApplicant(applicantData.applicant);
            setApplication(applicantData.application);
            setFiles(applicantData.files);
            setApplicantStatus(applicantData.application ? applicantData.application.status : null);
            setEmailVerified(applicantData.status.isEmailVerified === 1);
            setLoading(false);
            // }


        })


    }, [applicantId])

    const getProfileImagePath = () => {
        let profileImage = files.find(({type}) => type === "profile_image");
        if (profileImage)
            return API_ROOT_URL + "/uploads/files/" + profileImage.path;
        return "";
    }


    const isComplete = files && files.length > 0;
    const applicationStatusText = () => {

        if (!isComplete)
            return "Incomplete"
        return applicantStatus == null ? "Awaiting approval" : applicantStatus;

    }
    const approveApplication = (approve) => {

        const status = approve ? ApplicantStatus.Approved : ApplicantStatus.Disapproved;
        updateApplicantStatus(applicantId, status).then(() => {

            setApplicantStatus(status);

        });
    }

    const printLayout = () => {

        window.print();
    }


    const profileImageUrl = getProfileImagePath();


    if (!isLoading && applicant.id > 0 && !applicant.active) {
        return <Redirect to={AdminUrls.ADMIN_APPLICANTS}/>;
    }


    return <AdminLayout isLoading={isLoading}>
        <Container>
            <Row className={"d-none d-print-block"}>
                <Col className={"text-center pb-3"}>
                    <img src={Site.logo} alt={"company logo"} style={{width: "140px", height: "auto"}}/>
                </Col>
            </Row>
            <Row className={"d-print-none"}>
                <Col className={"text-center mb-2"}>
                    <h1>{applicant.firstName}'s application ({applicationStatusText()})</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card
                        bg={"light"}

                        text={'dark'}
                        className="mb-2"
                    >
                        <Card.Header><strong>Personal info</strong></Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <tbody>
                                {profileImageUrl !== "" &&
                                <tr>
                                    <td>{""}</td>
                                    <td>
                                        <Col className={"text-right"}>
                                            <img src={profileImageUrl} alt={"profile pic"}
                                                 style={{width: "200px", height: "auto"}}/>
                                        </Col>
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td>Full name</td>
                                    <td>{applicant.firstName} {applicant.lastName}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td><a href={("mailto:" + applicant.email)}> {applicant.email}</a>
                                        {isEmailVerified && <p style={{color: "green"}}>Verified</p>}
                                        {!isEmailVerified && <p style={{color: "red"}}>unverified</p>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Phone number</td>
                                    <td><a href={("tel:" + applicant.mobileNumber)}> {applicant.mobileNumber}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Country of residence</td>
                                    <td><a
                                        href={("tel:" + applicant.countryOfResidence)}> {applicant.countryOfResidence}</a>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>


                <Col lg={12}>
                    <Card
                        bg={"light"}

                        text={'dark'}
                        className="mb-2"
                    >
                        <Card.Header><strong>Employment info</strong></Card.Header>
                        <Card.Body>

                            <Table responsive>
                                <tbody>
                                {application &&
                                <>

                                    <tr>
                                        <td>Country of qualification</td>
                                        <td>{application.countryOfQualification}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of qualification</td>
                                        <td>{removeTimeFromDateTime(application.dateOfQualification)}</td>
                                    </tr>
                                    <tr>
                                        <td>Work location</td>
                                        <td>{application.workLocation}</td>
                                    </tr>
                                    <tr>
                                        <td>Clinical experience</td>
                                        <td>{application.clinicalExperience}</td>
                                    </tr>

                                    <tr>
                                        <td>Speciality of type</td>
                                        <td>{application.specialityOfType}</td>
                                    </tr>

                                    <tr>
                                        <td>Speciality</td>
                                        <td>{application.speciality}</td>
                                    </tr>

                                    <tr>
                                        <td>Additional Information</td>
                                        <td>{application.additionalInformation}</td>
                                    </tr>
                                </>

                                }
                                </tbody>
                            </Table>


                        </Card.Body>
                    </Card>
                </Col>


                <Col lg={12} className={"d-print-none"}>
                    <Card
                        bg={"light"}
                        className="mb-2"
                    >
                        <Card.Header><strong>Documents</strong></Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <tbody>
                                {
                                    files &&
                                    files.map((file, index) => {


                                        return (<tr key={index}>
                                            <td>{file.type}</td>
                                            <td><a href={(API_ROOT_URL + "/uploads/files/" + file.path)}
                                                   target={"_blank"}>{file.originalName}</a></td>
                                        </tr>);

                                    })

                                }
                                </tbody>
                            </Table>

                        </Card.Body>


                    </Card>
                </Col>
            </Row>
            <Row className={"d-none d-print-block"}>
                <Col>
                    <p>Telephone: <a href={"tel:" + Site.phoneNumber}>{Site.phoneNumber}</a> / <a
                        href={"tel:" + Site.alternatePhoneNumber}>{Site.alternatePhoneNumber}</a><br/>
                        Email: <a href={"mailto:" + Site.contactEmail}>{Site.contactEmail}</a><br/>
                        {Site.name}</p>
                </Col>
            </Row>


            {isComplete &&
            <Row className={"d-print-none"}>
                <Col className={"text-left"}>
                    <Button className={"m-1 btn-secondary"} onClick={() => printLayout()}>Print </Button>
                </Col>
                <Col className={"text-right"}>
                    {applicantStatus !== ApplicantStatus.Disapproved &&
                    <Button variant={"warning"} className={"m-1"}
                            onClick={() => approveApplication(false)}>Disapprove </Button>
                    }
                    {applicantStatus !== ApplicantStatus.Approved &&
                    <Button className={"m-1"} onClick={() => approveApplication(true)}>Approve </Button>
                    }


                </Col>
            </Row>
            }

            {isCurrentUserOwner &&
                <>
            <Row>
                <Col className={"text-right"}>
                    <div className={"m-3"}>
                        <Link to={AdminUrls.ADMIN_EDIT_USER + applicant.id}>Make user admin</Link>
                    </div>
                </Col>
            </Row>

            < DeleteConfirm applicant={applicant} onDeleted={(deletedApplicant) => {
                setApplicant(deletedApplicant)
            }}/>
            </>
            }

        </Container>

    </AdminLayout>

};

export default WithUserRoles(WithProgressBar(Applicant), [UserRoles.ADMIN]);
