//export const  API_ROOT_URL =  "http://localhost:8880";
export const  API_ROOT_URL =  "https://ukare.uk/api";

export const AdminUrls ={
    ADMIN_USERS:"/admin/users",
    ADMIN_EDIT_USER:"/admin/users/edit/",
    ADMIN_ADD_USER:"/admin/users/add",
    ADMIN_APPLICANT:"/admin/applicant/",
    ADMIN_APPLICANTS:"/admin/applicants/",
    TERMS :"/terms-and-conditions",
    PRIVACY :"/privacy",
    ABOUT: '/about',
    CONTACT:'/contact',
    COOKIES:'/cookies',
    LEGAL_NOTICE:'/legal-notice'

}