import React from 'react';
import Site from "../util/Site";
import {Col, Container,  Row} from "react-bootstrap";
import {AdminUrls} from "../urls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter , faLinkedin ,faInstagram , faFacebook ,faPinterest,faYoutube} from '@fortawesome/free-brands-svg-icons'

const  Footer=()=> {

    return (<>
        <Container className={"mt-5 d-print-none"}>
            <Row>
                <Col className={"text-center"} sm={12}>
                    <a href={Site.social.twitter} ><FontAwesomeIcon icon={faTwitter} size={"4x"} className={"p-2"}  /></a>
                    <a href={Site.social.instagram} ><FontAwesomeIcon icon={faInstagram} size={"4x"}  className={"p-2"} /></a>
                    <a href={Site.social.linkedIn} ><FontAwesomeIcon icon={faLinkedin} size={"4x"}  className={"p-2"} /></a>
                    <a href={Site.social.facebook} ><FontAwesomeIcon icon={faFacebook} size={"4x"}  className={"p-2"} /></a>

                    {Site.social.pinterest &&
                        <a href={Site.social.pinterest}><FontAwesomeIcon icon={faPinterest} size={"4x"} className={"p-2"}/></a>
                    }
                    {Site.social.youtube &&
                    <a href={Site.social.youtube}><FontAwesomeIcon icon={faYoutube} size={"4x"} className={"p-2"}/></a>
                    }
                </Col>
                <Col className={"text-center"} sm={12}>
                    <a href={AdminUrls.TERMS} >  Terms of use  </a>
                    <a href={AdminUrls.COOKIES} >  Cookies  </a>
                    <a href={AdminUrls.PRIVACY} >  Privacy policy  </a>
                    <a href={AdminUrls.LEGAL_NOTICE} >  Legal notice  </a>

                </Col>

            </Row>
        </Container>

        <p className="text-center mt-3"> © Copyright { (new Date()).getFullYear()} {Site.name}</p></>)

}


export default Footer;