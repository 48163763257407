import React, {useEffect, useState} from "react";
import {Col, Container, Row, Form, Alert, Button} from "react-bootstrap";
import ApplicantLayout from "../../../components/applicant/ApplicantLayout";
import UserRegistrationForm from "../../../components/UserRegistrationForm";


import Progress from "../../../components/Progress";
import {getUserById, registerUser, editUser} from "../../../api/user";
import {getCurrentUser, getUserRoleDescription, UserRoles} from "../../../services/user";
import {Redirect, useParams} from "react-router-dom";
import {AdminUrls} from "../../../urls";
import {WithUserRoles} from "../../../components/admin/WithUserRoles";

const AddUser = () => {


    let {userId} = useParams();

    const [error, setError] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    //const [isFormValidated, setFormValidated] = useState(false);
    const [editableUser, setEditableUser] = useState({});

    const [userRoles, setUserRoles] = useState([{
        id: UserRoles.ADMIN,
        value: UserRoles.ADMIN,
        checked: true
    },  {id: UserRoles.USER_MANAGEMENT, value: UserRoles.USER_MANAGEMENT, checked: false},
        {id: UserRoles.OWNER, value: UserRoles.OWNER, checked: false}]);
    const [isComplete, setComplete] = useState(false);
    const isEditMode = userId > 0;

    useEffect(() => {

        if (isEditMode)
            getUserById(userId).then(user => {

                let newRoles = [];
                const allRoles = Object.values(UserRoles);


                for (let i = 0; i < allRoles.length; i++) {
                    let hasRole = false;
                    for (let roleIndex = 0; roleIndex < user.roles.length; roleIndex++) {
                        if (user.roles[roleIndex] === allRoles[i]) {
                            hasRole = true;
                        }

                    }
                    newRoles.push({id: allRoles[i], value: allRoles[i], checked: hasRole})
                }

                setUserRoles(newRoles);
                setEditableUser(user)

            })

    }, [userId, isEditMode])


    const OnSubmit = (inputs) => {

        setEditableUser(inputs);
        const rolesArray = userRoles.filter(({id, checked}) => checked).map(({id}) => id);
        setShowProgress(true);
        let data = {...inputs, roles: rolesArray, acceptedTerms: true};
        if (isEditMode) {
            editUser(data, progressPercentage => {
                setUploadPercentage(progressPercentage);
            }).then(res => {
                setShowProgress(false);
                setComplete(true);


            }).catch((message) => {
                setShowProgress(false);
                setError(message);

            });
        } else {
            registerUser(data, progressPercentage => {
                setUploadPercentage(progressPercentage);
            }).then(res => {
                setShowProgress(false);
                setComplete(true);


            }).catch((message) => {
                setShowProgress(false);
                setError(message);

            });
        }

        //setFormValidated(true);
    }
    const onSubmitFailure = (inputs) => {

        //setFormValidated(true);
    }

    const OnDeActivateAccount = () => {

        let data = {...editableUser, active: false, acceptedTerms: true};
        editUser(data, progressPercentage => {
            setUploadPercentage(progressPercentage);
        }).then(res => {
            setShowProgress(false);
            setComplete(true);


        }).catch((message) => {
            setShowProgress(false);
            setError(message);

        });

    }

    const toggleRole = (roleId) => {

        let newRoles = userRoles.map(role => {

            let checked = role.checked;
            if (role.id === roleId) {
                checked = !checked;
            }
            return {...role, checked: checked};
        });

        setUserRoles(newRoles);


    }
    if (isComplete) {
        return <Redirect to={AdminUrls.ADMIN_USERS}/>;
    }
    if (showProgress) {
        return (<Progress percentage={uploadPercentage}/>);

    }
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1>{isEditMode ? "Update staff member" : "Add staff member"}</h1>
                        {
                            error &&
                            <Alert key="errormessage" variant="danger">
                                {error}
                            </Alert>
                        }
                        <UserRegistrationForm onSubmit={OnSubmit} onSubmitFailure={onSubmitFailure}
                                              submitButtonTitle={isEditMode ? "Update" : "Add now"}
                                              inputs={editableUser}>

                            <Form.Group>
                                <label>Select user roles</label>
                                <br/>
                                {

                                    userRoles.map(({id, value, checked}) => {

                                        return <Form.Check
                                            key={id}
                                            style={{display: "inline-block", marginRight: "15px"}}
                                            label={getUserRoleDescription( value)}
                                            feedback="You must agree before submitting."
                                            name={"role" + id}
                                            checked={checked}
                                            disabled={!getCurrentUser().roles.includes(UserRoles.OWNER) &&value === UserRoles.OWNER}
                                            //isInvalid={validated &&!inputs.acceptedTerms}
                                            onChange={() => toggleRole(id)}
                                        />
                                    })
                                }
                            </Form.Group>

                        </UserRegistrationForm>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-right mt-5"}>
                        {
                            isEditMode &&
                            <Button className="btn-cta btn-danger" type="button"
                                    disabled={!getCurrentUser().roles.includes(UserRoles.OWNER)}
                                    onClick={OnDeActivateAccount}>DeActivate</Button>
                        }
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>
    );
};

export default WithUserRoles(AddUser, [UserRoles.USER_MANAGEMENT, UserRoles.OWNER]);