import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import TermsContent from "./TermsContent";

const TermsFormCheck =(props)=>{

    const [showTermsAndConditions , setShowTermsAndConditions] = useState(false);
    const [hasAcceptedTerms ,setAcceptedTerms ] = useState(false);//props.acceptedTerms);
    let termsStyle =  props.validated &&!hasAcceptedTerms? {display:"inline-block",cursor:"pointer",color:"red"} : {display:"inline-block",cursor:"pointer"};

    return <>
        <Form.Check
            style={{display:"inline-block"}}
            required
            label=""
            feedback="You must agree before submitting."
            name="acceptedTerms"
            defaultChecked={hasAcceptedTerms}
            //isInvalid={validated &&!inputs.acceptedTerms}
            onChange={()=>setAcceptedTerms(!hasAcceptedTerms)}
        />
        <a   onClick={()=>setShowTermsAndConditions(true)} style={termsStyle}>Agree to terms and conditions</a>
        <Modal  size="lg" show={showTermsAndConditions} onHide={()=>setShowTermsAndConditions(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body> <TermsContent/></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowTermsAndConditions(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}

export default  TermsFormCheck;