import React, {useState} from 'react';
import axios from 'axios';
import {Alert, Button, Form} from "react-bootstrap";
import {loginUser } from "../actions";
import {useDispatch} from "react-redux";
import {API_ROOT_URL} from "../urls";
import Site from "../util/Site";
const LoginForm =()=>{


    const [validated, setValidated]  = useState(false);
    const [error, setError]  = useState("");
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const handleInputChange=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputs(inputs => ({...inputs, [name]: value}));

    };

    const handleSubmit = (event)=>
    {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            let data ={...inputs,appId:Site.clientId};
            axios.post(  API_ROOT_URL +"/user/token/",  data )
                .then(res => {
                    dispatch(loginUser(res.data.accessToken , res.data.user.firstName, res.data.user.roles));


                }).catch((error) => {
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if(error.response.status === 404)
                    {
                        setError("Invalid email ID or password used")

                    }

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */

                } else {
                    // Something happened in setting up the request and triggered an Error

                }

            });
        }
        setValidated(true);


    };


        return (
            <div>
                {error !=="" &&
                <Alert key="errormessage" variant="danger">
                    {error}
                </Alert>
                }
                <Form noValidate validated={validated} onSubmit={handleSubmit}>




                    <Form.Group   controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                            name="email"
                            defaultValue={inputs.email}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group    controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            placeholder="******"
                            name="password"
                            defaultValue={inputs.password}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-right">
                    <Button className="btn-cta" type="submit">Login</Button>
                        <a href="/recover" className="mt-3" style={{display:"block"}}>Forgotten password</a>
                    </div>

                </Form>
            </div>
        );

};



export default LoginForm;