import React from 'react';
import './App.css';
import Main from "./components/Main";
import Site from "./util/Site";
import {Helmet} from "react-helmet";

function App() {

  return (
    <div className="App">
        <Helmet>
            <title> {Site.name}</title>
            <meta name="description" content={`${Site.name} is hеrе tо ѕuрроrt hеаlth care practitioners frоm аrоund the wоrld lаunсh thеіr саrееrѕ іn ${Site.country}. Our influential іnduѕtrу еxреrtѕ ѕuррlу, ѕuрроrt, and cultivate еxреrtѕ thаt healthcare оrgаnіzаtіоnѕ retain`} />
        </Helmet>
       <Main/>
    </div>
  );
}

export default App;
