import Site  from "../util/Site";
import React from "react";

export const CompanyAddress =({breakLines = false})=>{

    if(breakLines)
    {
        const lines = Site.companyAddress.split(",");

        return (lines.map(line  => {return <>{line} <br/></>} ));

    }
        return (
            <>
                {Site.companyAddress}
            </>
        )


};

const CompanyInfo =()=> {

    return (
        <>
            {Site.companyName}<br/>
            Registration number {Site.registrationNumber}<br/>
            <CompanyAddress breakLines={true}/>
            Email: {Site.contactEmail}<br/>
        </>
    )



}
export default CompanyInfo;