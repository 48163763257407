import React from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";

import ApplicantLayout from "../components/applicant/ApplicantLayout";
import Site from "../util/Site";
import CompanyInfo from "../components/CompanyInfo";


const Privacy = () => {
    return (
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1 dir="ltr">Privacy Policy</h1>
                        <p>
                            This Policy applies as between you, the User of this Web Site and {Site.companyName} the owner and
                            provider of this Web Site. This Policy applies to our use of any and all Data collected by
                            us in relation to your use of the Web Site and our Services. This Policy was last updated on
                            the 02 August 2020.
                        </p>
                        <div>
                            <h5>
                                The Regulation

                            </h5>
                            <p>
                                This Data Protection Policy applies to the services of {Site.companyName}. If you live in the
                                European Economic Area, Regulation (EU) 2016/679 (General Data Protection Regulation) is
                                the European Regulation and in {Site.country}, it is the Data Protection Act 2018.
                            </p>
                            <h5>The Controller

                            </h5>
                            <p>{Site.companyName} with registration number {Site.registrationNumber}  of {Site.companyAddress} is the Controller of your personal data.

                            </p>
                            <h5>The Supervisory Authority

                            </h5>
                            <p>The Data Protection Commission (DPC) in {Site.country} is the for us relevant authority in matters of data protection. You have the right to make a complaint at any time to the DPC (www.dataprotection.ie). We would, however, appreciate the chance to deal with your concerns before you approach the DPC so please contact us in the first instance.

                            </p>
                            <h5>Accuracy

                            </h5>
                            <p>It is important that the data we hold about you is accurate and current, therefore please keep us informed of any changes to your personal data.

                            </p>
                            <h5>Children Data

                            </h5>
                            <p>Our website is not intended for children and we do not knowingly collect data relating to children.

                            </p>
                            <h5>What is personal data?

                            </h5>
                            <p>Personal data refers to any information relating to an identified or identifiable natural person (“Personal Data”).

                            </p>
                            <h5>What data do we collect?

                            </h5>
                            <p>We may collect data or ask you to provide certain data when you use our website and services. The sources from which we collect Personal Data are:
                            </p>
                            <h6>Candidates</h6>
                            <ul>
                                <li>personal and contact details (for example your name, email address, date of birth, gender);</li>
                                <li>personal and contact details you give us when subscribing to receive emails, newsletters or marketing information from us;</li>
                                <li>during pre-assignment vetting we will request details from you including, your name, your work history, qualifications, contact details (such as email, telephone number and home address), your right to work documents, details required for equality and discrimination legislation checks and your personal preferences, choices and requirements specific to particular requests or services;</li>
                                <li>details of your education, employment history, bank details and national insurance number, references, right to work and other information you tell us about yourself (e.g. the information contained within your CV) when you engage with us for the provision of services;</li>
                                <li>information from social media activity (such as likes, shares and tweets) when you interact with us on social media;</li>
                                <li>additional information which you provide voluntarily and/or which we may ask from you to better understand you and your interests</li>
                                <li>We may also collect sensitive Personal Information about you (including details of your physical or mental health, racial or ethnic origin, criminal allegations or offences, trade union membership and/or other sensitive Personal Information that you may choose to provide to us voluntarily from time to time</li>

                            </ul>
                            <p>We link data and if we have already collected some of your data, we will only ask you for the remaining data that is necessary to carry out the service contracted for.</p>

                            <h5>How personal data is collected</h5>
                            <p>We collect personal data in the following ways:

                            </p>
                            <Table>
                                <tbody>
                                <tr>
                                    <td>direct interactions </td>
                                    <td>you may provide personal data when you complete online forms, request services, subscribe to our services, create a user account, join our mailing list, use our feedback form or otherwise or correspond with us (by post, phone or email)
                                    </td>
                                </tr>
                                <tr>
                                    <td>automated technology</td>
                                    <td>we automatically collect personal data (technical and usage) when you browse or interact with our website, by using cookies, and other similar technologies. We may also receive technical data about you if you visit other websites which use our cookies.
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            <h5>On what grounds do we use Personal Data?</h5>
                            <p>We use your Personal Data for the following purposes and on the following grounds:


                            </p>
                            <p>On the basis of fulfilling our contract (when you use our service)

                            </p>
                            <p>On the basis of your consent (when you subscribe to our newsletter and when you contact us)

                            </p>
                            <p>On the basis of legal obligations (for obligations such as tax, accounting, anti-money laundering, or when a court or other authority asks us to)

                            </p>
                            <p>On the basis of our legitimate interest (for communications about security, privacy and performance improvements of our services. Or for establishing, exercising or defending our legal rights.)

                            </p>
                            <p>Of course, before relying on any of those legitimate interests we balance them against your interests and make sure they are compelling enough and will not cause any unwarranted harm.

                            </p>
                            <h5>When do we disclose your Personal Data?</h5>
                            <p>We disclose your Personal Data in response to your business enquiry or your request for information within our Company in order to provide the best service possible and within our legitimate interest.

                            </p>
                            <p>We may share your information with organisations that help us provide the services described in this Data Protection Policy and who may process such data on our behalf and in accordance with this Data Protection Policy, to support this website and our services. For example, with our legal other professional advisors.

                            </p>
                            <p>In relation to information obtained about you from your use of our website, we may share a cookie identifier and IP data with analytic and advertising network services providers to assist us in the improvement and optimisation of our website which is subject to our Cookies Policy.

                            </p>
                            <p>We may disclose personal information in other circumstances such as when you agree to it or if the law, a Court order, a legal obligation or regulatory authority ask us to. If the purpose is the prevention of fraud or crime or if it is necessary to protect and defend our right, property or personal safety of our staff, the website and its users.

                            </p>

                            <h5>
                                Data retention
                            </h5>
                            <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.


                            </p>
                            <p>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us.  However, we are legally required to keep basic information about our customers (including contact, identity, financial and transaction data) for six years after they cease being customers, for tax purposes.


                            </p>
                            <p>You can learn how long your information is held and when it is destroyed from our Data Retention Policy which you can request using our contact form.


                            </p>
                            <h5>Your Rights</h5>
                            <p>You have the right to:</p>
                            <ul>
                                <li>information about the processing of your personal data</li>
                                <li>obtain access to the personal data held about you</li>
                                    <li>ask for incorrect, inaccurate or incomplete personal data to be corrected</li>
                                        <li>request that personal data be erased when it’s no longer needed or if processing it is unlawful</li>
                                            <li>object to the processing of your personal data for marketing purposes or on grounds relating to your particular situation</li>
                                                <li>request the restriction of the processing of your personal data in specific cases</li>
                                                    <li>receive your personal data in a machine-readable format and send it to another controller (‘data portability’)</li>
                                                        <li>request that decisions based on automated processing concerning you or significantly affecting you and based on your personal data are made by natural persons, not only by computers. You also have the right in this case to express your point of view and to contest the decision</li>
                                                            <li>Where the processing of your personal information is based on consent, you have the right to withdraw that consent without detriment at any time through our contact form.</li>

                            </ul>
                            <p>
                                If you feel that your request is not satisfactorily resolved by us, you may approach your local data protection authority. The Data Protection Commission (DPC) is the supervisory authority in {Site.country} and relevant to {Site.companyName}.
                            </p>
                            <h5>How do we protect your Personal Data?
                            </h5>
                            <p>We protect your data using state of the art technical, and physical safeguards and operate a firm system of policies, confidentiality agreements, digital safeguards and procedures to ensure the highest level of administrative protection.

                            </p>
                            <p>In more detail to access our database the user must be authorised, is challenged through a two-way authentication system and use an encrypted VPN. Also, the removal of Personal Data from our location is forbidden and made by using a complex encryption system very difficult. We use cutting edge antivirus and anti-malware software and up-to-date firewall protection. Moreover, authorised personnel must have a legitimate need to know interest such as being your point of contact or service your user account.

                            </p>
                            <p>
                                The data we collect from you may be stored, with appropriate technical and organisational security measures applied to it, on our servers in {Site.country}. In all cases, we follow generally high data protection standards and advanced security measures to protect the personal data submitted to us, both during transmission and once we receive it.
                            </p>
                            <p>To exercise any of your rights, or if you have any questions or complaints about our use of your Personal Data and this policy, please contact us using our contact form.
                            </p>
                            <h5>International transfers</h5>
                            <p>
                                We may share your Personal Information with our third party service providers based in the European Economic Area (“EEA”) who we engage to help us to process the information that we collect from you as part of our business function, and/or to host and maintain our CRM systems, content or services, on our behalf and in accordance with this Privacy Policy. We will let you know in advance if we need to transfer your Personal Information to any third-party service providers located outside of the EEA and we will only transfer your Personal Information outside of the EEA:
                            </p>

                            <ul>
                                <li>where the transfer is to a place that is regarded by the European Commission as providing adequate protection for your Personal Information; or

                                </li>
                                <li>where we have put in place appropriate safeguards, for example by using a contract for the transfer which contains specific data protection provisions that have been adopted by the European Commission or a relevant data protection authority. You can request a copy of these contracts by contacting us at: {Site.contactEmail}; or

                                </li>
                                <li>
                                    where you have consented to it, or there is another legal basis to allow us to make the transfer.
                                </li>
                            </ul>
                            <h5>Changes</h5>
                            <p>
                                This Data Protection Policy and our commitment to protecting the privacy of your personal data can result in changes to this Data Protection Policy. Please regularly review this Data Protection Policy to keep up to date with any changes.
                            </p>
                            <h5>Queries and Complaints </h5>
                            <p>
                                Any comments or queries on this policy should be directed to us using the following contact details.
                            </p>
                            <div className={"text-left"}>
                            <CompanyInfo/><br/>
                            </div>

                            <p>
                                If you believe that we have not complied with this policy or acted otherwise than in accordance with data protection law, then you should notify us. You can also make a referral to, or lodge a complaint with, the Data Protection Commission.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>);

};
export default Privacy ;