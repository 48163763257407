export const FILE_TYPE_CERTIFICATE = "certificate";
export const FILE_TYPE_PROFILE_PICTURE = "profile_image" ;
export const FILE_TYPE_ID = "id";
export const FILE_TYPE_CV = "cv";
export const FILE_TYPE_DR_LETTERS = "dr_letters";
export const FILE_TYPE_POLICE_CHECKS = "police_checks";


let fileId = 1;

const incrementFileId =()=>{

    fileId++;
    return fileId;
};

export class File{

    constructor(fileType)
    {
        this.name = fileType+incrementFileId();
        this.id = "";
        this.file =null;
        this.filename ="Choose a file";
        this.type = fileType;

    }

}

