import {LOGIN_USER,LOG_OUT ,UPDATED_APPLICATION} from "./actionTypes";
import {combineReducers} from "redux";
import {localStorageService} from "./services/localStorgeService";
import {getCurrentUser, setCurrentUser} from "./services/user";

let userInitialState =  getCurrentUser();



function userReducer(state = userInitialState,  action) {

    switch (action.type) {
        case LOGIN_USER: {
            const newState = {
                isLoggedIn: true,
                accessToken: action.payload.accessToken,
                firstName: action.payload.firstName,
                roles : action.payload.roles
            };
           setCurrentUser(newState);
            return newState;
        }
        case LOG_OUT: {
            localStorageService.removeItem("user");
            localStorageService.removeItem("applicationStatus");
            return  {
                isLoggedIn: false,
                accessToken: "",
                firstName: ""
            };
        }

        default:
            return state;
    }
}

let applicationState = {
   employment :0,
files :0,
emailVerified :0,
    complete :0
};

if(localStorageService.getItem("applicationStatus")!== null)
{
    applicationState =  JSON.parse(localStorage.getItem("applicationStatus"));
}
function applicationReducer(state = applicationState, action)
{

    if(action.type === UPDATED_APPLICATION )
    {
        let newState = action.payload;
       // alert(JSON.stringify( newState));
        if(newState.files === 1 && newState.employment === 1  && newState.emailVerified ===1)
        {
            newState.complete = 1;
        }
        else {
            newState.complete =0;
    }

        localStorageService.setItem("applicationStatus", JSON.stringify(newState));
        return {...newState}
    }
    else return state;
}

function someOtherReducer(state = { firstName: ""}, action) {
    switch (action.type) {
        case "WHATEVER":
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
}


const   rootReducer = combineReducers({user: userReducer, someOther: someOtherReducer,applicationStatus: applicationReducer})
export default rootReducer;