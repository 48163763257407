import axios from "axios";
import {API_ROOT_URL} from "../urls";
import {getCurrentUser} from "../services/user";
import Site from "../util/Site";

export const registerUser=(user, onProgressUpdate)=>{

    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/user/create/", {...user, appId: Site.clientId}, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                onProgressUpdate(
                    parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    )
                );
            }
        })
            .then(res => {

                resolve(res);


            }).catch((error) => {
            // Error 😨
            if (error.response) {

                if (error.response.status === 409) {
                    reject(error.response.data.error);

                }

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}
export  const getUsersByRoles=(roles, onProgressUpdate)=>{

    const currentUser = getCurrentUser();
    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/users/", {...currentUser,roles:roles,  appId: Site.clientId}, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                onProgressUpdate(
                    parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    )
                );
            }
        })
            .then(res => {

                resolve(Object.values( res.data.users));


            }).catch((error) => {
            // Error 😨
            if (error.response) {

                //alert(JSON.stringify( error))
                    reject(error.response.data.error);

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}
export const getUserById=(userId, onProgressUpdate)=>{

    const currentUser = getCurrentUser();
    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/user/id/", {...currentUser,userId:userId,  appId: Site.clientId}, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                    onProgressUpdate(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
            }
        })
            .then(res => {
                resolve( res.data.user);


            }).catch((error) => {
            // Error 😨
            if (error.response) {

                //alert(JSON.stringify( error))
                reject(error.response.data.error);

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}

export const editUser=(userToUpdate , onProgressUpdate)=>{

    const currentUser = getCurrentUser();
    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/user/update/", {...currentUser,userToUpdate:userToUpdate,  appId:Site.clientId}, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                    onProgressUpdate(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
            }
        })
            .then(res => {
                //alert(JSON.stringify( res.data));

                resolve(Object.values( res.data.status));


            }).catch((error) => {
            // Error 😨
            if (error.response) {

                //alert(JSON.stringify( error))
                reject(error.response.data.error);

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}
