import {getCurrentUser} from "../services/user";
import axios from "axios";
import {API_ROOT_URL} from "../urls";
import Site from "../util/Site";

export  const getApplicants=( onProgressUpdate,applicantId  = 0)=>{

    const currentUser = getCurrentUser();
    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/applicants/", {...currentUser,applicantId:applicantId,  appId: Site.clientId }, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                    onProgressUpdate(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
            }
        })
            .then(res => {
                resolve(Object.values( res.data.applicants));

            }).catch((error) => {
            // Error 😨
            if (error.response) {

                reject(error.response.data.error);

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}


export  const getApplicant=( applicantId ,  onProgressUpdate)=> {
    return getApplicants(onProgressUpdate,applicantId);
}


export  const updateApplicantStatus=( applicantId  ,status , onProgressUpdate)=>{

    const currentUser = getCurrentUser();
    return new Promise((resolve  , reject )=> {
        axios.post(API_ROOT_URL + "/applicant/approve/", {...currentUser,applicantId:applicantId,status:status,  appId: Site.clientId }, {

            onUploadProgress: progressEvent => {
                if(onProgressUpdate)
                    onProgressUpdate(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
            }
        })
            .then(res => {
                resolve(true);

            }).catch((error) => {
            // Error 😨
            if (error.response) {

                reject(error.response.data.error);

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                reject('The request was made but no response was received, error.request')

            } else {
                // Something happened in setting up the request and triggered an Error
                reject('Something happened in setting up the request and triggered an Error');

            }
        });
    })
}


export const ApplicantStatus = {
    Incomplete : "Incomplete",
    AwaitingEmailVerification :"Awaiting email verification",
    AwaitingApproval : "Awaiting approval",
    Approved :"Approved",
    Disapproved:"Disapproved"

}

export const getStatusApplicationStatus= (status)=>
{
    if(status.isEmailVerified ===0)
    {
        return ApplicantStatus.AwaitingEmailVerification;
    }
    else if(status.employment !==1 || status.files !==1 )
    {
        return ApplicantStatus.Incomplete
    }
    if( status.approved ===ApplicantStatus.Approved)
    {
        return ApplicantStatus.Approved;

    }
    if( status.approved ===ApplicantStatus.Disapproved)
    {
        return ApplicantStatus.Disapproved;

    }

    return ApplicantStatus.AwaitingApproval;

}
