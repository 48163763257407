import {LOGIN_USER, LOG_OUT, UPDATED_APPLICATION} from "./actionTypes";

export function loginUser( accessToken, firstName , roles ) {

    const payload  = {accessToken:accessToken,firstName:firstName ,roles : roles};
    return {type:LOGIN_USER,payload:payload};

}
export function logOutAction() {
    return {type:LOG_OUT};
}

export function updateApplicationStatus(data) {
    const payload  = {...data};
    return {type:UPDATED_APPLICATION,payload:payload};

}
