import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import CookieConsent from "../CookieConsent";
const ApplicantLayout = ({children,isLoading}) =>{

    if(isLoading)
        return null;

    return <>
        <Header/>
        {
            children
        }

        <Footer/>
        <CookieConsent/>
    </>

}
export default ApplicantLayout;