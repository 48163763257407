import React from "react";

export const ClientId={
    Ukare:"ukare",
    Caricare :"caricare"

};
const ActiveClient  = ClientId.Ukare;


const Site = ActiveClient === ClientId.Caricare ? {
    clientId: "caricare",
    name: "Caricare",
    companyName: "Caricare Ltd",
    registrationNumber:"663423",
    companyAddress : "22 Milltown meadows Donaghmore, Ashbourne Co Meath Republic of Ireland, A84HF59",
    country: "Ireland",
    webAddress: "www.caricare.ie",
    contactEmail: "admin@caricare.ie",
    phoneNumber: "+35319609083",
    alternatePhoneNumber:"+353899542321",
    logo: "/logoCariCare.png",
     siteIntroMessage :"We will help you relocate to the most convenient locations to live in Ireland",
    social:{
        twitter:"https://twitter.com/CariCare6",
        instagram:"https://www.instagram.com/caricare6/",
        linkedIn:"https://www.linkedin.com/company/caricare6",
        facebook :"https://web.facebook.com/CariCare-103097108169177/?modal=admin_todo_tour"
    }
}: {
    clientId: "ukare",
    name: "UKare Recruitment",
    companyName: "Ukare Recruitment Ltd",
    registrationNumber:"11200263",
    companyAddress : "16 South Square, London NW11 7AL",
    country: "United Kingdom",
    webAddress: "ukare.uk",
    contactEmail: "info@ukarerecruitment.co.uk",
    phoneNumber: "+44203889078",
    alternatePhoneNumber:"+442038134771",
    logo: "/logo.png",
    siteIntroMessage :"We will help you relocate to the most convenient locations to live in UK",
    social:{
        twitter:"https://twitter.com/Ukareltd",
        instagram:"https://www.instagram.com/ukareltd/",
        linkedIn:"https://www.linkedin.com/company/ukare-ltd",
        facebook :"https://web.facebook.com/Ukare-ltd-623903451599941/?modal=admin_todo_tour",
        pinterest:"https://www.pinterest.com/Ukareltd/",
        youtube:"https://www.youtube.com/channel/UCEAv56lEeefHzcV2g_a8z_g/featured?disable_polymer=1"
    }
};



export default Site;
