import React, {useEffect, useState} from "react";
import {Col, Container, Row ,Table,Dropdown} from "react-bootstrap";
import AdminLayout from "../../components/admin/AdminLayout";
import {AdminUrls} from "../../urls";
import {getStatusApplicationStatus, getApplicants, ApplicantStatus} from "../../api/applicant";
import {WithUserRoles} from "../../components/admin/WithUserRoles";
import WithProgressBar from "../../components/WithProgressBar";
import {UserRoles} from "../../services/user";

const Applicants = ({setLoading,setLoadingPercentage,isLoading}) => {

    const [users, setUsers] = useState([]);
    const [selectedFilter , setSelectedFilter] = useState(null);
    useEffect(()=>{

        setLoading(true);
        getApplicants(setLoadingPercentage).then(applicants=>{

            let updatedApplicants = [];
            for(let i = 0; i< applicants.length; i++)
            {
                let applicant = applicants[i].applicant;
                applicant.application = applicants[i].application;
                applicant.files  = applicants[i].files;
                applicant.status = applicants[i].status;
                updatedApplicants.push( applicant);

            }
            setUsers(updatedApplicants);
           setLoading(false);


        }).catch(message=>{


        })

    },[])


    const activeUsers = users.filter(user => (user.active && !user.roles.includes("ADMIN"))).filter(user=>{

        if(selectedFilter === null)
            return true;
        return selectedFilter === getStatusApplicationStatus(user.status);
    })


    const statusFiltersArray = Object.values(ApplicantStatus);

    return <AdminLayout isLoading={isLoading}>
        <Container className={"mb-3"}>
            <Row>
                <Col>
                    <h1>Manage Applicants</h1>
                </Col>
                <Col className={"text-right"} sm={12}>
                    <Dropdown >
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Filter by status
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item  onClick={()=>setSelectedFilter(null)}  active={null === selectedFilter}>All</Dropdown.Item>
                            {
                                statusFiltersArray.map((status,index) =>{

                                    return <Dropdown.Item key={index}  onClick={()=>setSelectedFilter(status)} active={status === selectedFilter}>{status}</Dropdown.Item>;
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
        <Container >
            <Row>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Full name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>{""}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        activeUsers.map((user,index)=><tr key={index}><td>{user.firstName} {user.lastName}</td><td><a href={"mailto:"+user.email}>{user.email}</a></td><td>{getStatusApplicationStatus( user.status)}</td><td>< a href={AdminUrls.ADMIN_APPLICANT+user.id} >View</a></td></tr>)
                    }
                    </tbody>
                </Table>

            </Row>

        </Container>

    </AdminLayout>

};

export default WithUserRoles(WithProgressBar(Applicants), [UserRoles.ADMIN]);
