import { Table} from "react-bootstrap";
import React from "react";
import Site from "../util/Site";

const TermsContent = () => {
    
    return (
        <div>
            <p>This agreement applies as between you, the User of this Web Site and {Site.companyName} LTD, the owner(s) of this
                Web Site. Your agreement to comply with and be bound by these terms and conditions is deemed to occur
                upon your first use of the Web Site. If you do not agree to be bound by these terms and conditions, you
                should stop using the Web Site immediately.</p>

            <h5>Definitions and Interpretation</h5>
            <Table>
                <tbody>
                <tr>
                    <td>“Content”</td>
                    <td>means any text, graphics, images, audio, video, software, data compilations and any other form
                        of information capable of being stored in a computer that appears on or forms part of this Web
                        Site;
                    </td>
                </tr>
                <tr>
                    <td>“{Site.companyName}”</td>
                    <td>means {Site.companyName} LTD a company registered in {Site.country} under registration number {Site.registrationNumber} of {Site.companyAddress};
                    </td>
                </tr>
                <tr>
                    <td>“Service”</td>
                    <td>means collectively any online facilities, services or information that {Site.companyName} makes available
                        through the Web Site either now or in the future;
                    </td>
                </tr>
                <tr>
                    <td>“System”</td>
                    <td>means any online communications infrastructure that {Site.companyName} makes available through the Web
                        Site either now or in the future. This includes, but is not limited to, web-based email, message
                        boards, live chat facilities and email links;
                    </td>
                </tr>
                <tr>
                    <td>“User” / “Users</td>
                    <td>means any third party that accesses the Web Site and is not employed by {Site.companyName} and acting in
                        the course of their employment; and
                    </td>
                </tr>
                <tr>
                    <td>“Web Site”</td>
                    <td>means the website that you are currently using ({Site.webAddress}).
                    </td>
                </tr>

                </tbody>

            </Table>

            <h5>Intellectual Property</h5>
            <p>All Content included on the Web Site, unless uploaded by Users, including, but not limited to, text,
                graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying
                code and software is the property of {Site.companyName}, our affiliates or other relevant third parties. By
                continuing to use the Web Site you acknowledge that such material is protected by applicable Irish and
                International intellectual property and other relevant laws.</p>

            <h5>Links to Other Web Sites</h5>
            <p>This Web Site may contain links to other sites. Unless expressly stated, these sites are not under the
                control of {Site.companyName} or that of our affiliates. We assume no responsibility for the content of such web
                sites and disclaim liability for any and all forms of loss or damage arising out of the use of them. The
                inclusion of a link to another site on this Web Site does not imply any endorsement of the sites
                themselves or of those in control of them.</p>

            <h5>Links to this Web Site</h5>
            <p>Those wishing to place a link to this Web Site on other sites may do so only to the home page of the site
                {Site.webAddress} without prior permission. Deep linking (i.e. links to specific pages within the site)
                requires the express permission of {Site.companyName}. To find out more please contact us by email at
                {Site.contactEmail}</p>

            <h5>Privacy</h5>
            <p>For the purposes of applicable data protection legislation, the {Site.companyName} will process any personal data
                you have provided to it in accordance Privacy Policy available on the {Site.companyName} website or on request
                from {Site.companyName}.</p>

            <p>You agree that, if you have provided {Site.companyName} with personal data relating to a third party (1) you have
                in place all necessary appropriate consents and notices to enable lawful transfer such personal data to
                {Site.companyName} and (2) that you have brought to the attention of any such third party the Privacy Notice
                available on the {Site.companyName}’s website or otherwise provided a copy of it to the third party. You agree to
                indemnify {Site.companyName} in relation to all and any liabilities, penalties, fines, awards or costs arising
                from your non-compliance with these requirements.</p>
            <h5>Disclaimers</h5>
            <p>{Site.companyName} makes no warranty or representation that the Web Site will meet your requirements, that it will
                be of satisfactory quality, that it will be fit for a particular purpose, that it will not infringe the
                rights of third parties, that it will be compatible with all systems, that it will be secure and that
                all information provided will be accurate. We make no guarantee of any specific results from the use of
                our Service.
            </p>
            <p>No part of this Web Site is intended to constitute advice and the Content of this Web Site should not be
                relied upon when making any decisions or taking any action of any kind.</p>


            <h5>
                Availability of the Web Site

            </h5>
            <p>The Service is provided “as is” and on an “as available” basis. We give no warranty that the Service will
                be free of defects and / or faults. To the maximum extent permitted by the law we provide no warranties
                (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and
                satisfactory quality.
            </p>
            <p>
                {Site.companyName} accepts no liability for any disruption or non-availability of the Web Site resulting from
                external causes including, but not limited to, ISP equipment failure, host equipment failure,
                communications network failure, power failure, natural events, acts of war or legal restrictions and
                censorship.
            </p>

            <h5>Limitation of Liability
            </h5>
            <p>
                To the maximum extent permitted by law, {Site.companyName} accepts no liability for any direct or indirect loss or
                damage, foreseeable or otherwise, including any indirect, consequential, special or exemplary damages
                arising from the use of the Web Site or any information contained therein. Users should be aware that
                they use the Web Site and its Content at their own risk.

            </p>
            <p>
                Nothing in these terms and conditions excludes or restricts {Site.companyName}’s liability for death or personal
                injury resulting from any negligence or fraud on the part of {Site.companyName}.
            </p>
            <p>
                Every effort has been made to ensure that these terms and conditions adhere strictly with the relevant
                provisions of the Unfair Terms Regulations. However, in the event that any of these terms are found to
                be unlawful, invalid or otherwise unenforceable, that term is to be deemed severed from these terms and
                conditions and shall not affect the validity and enforceability of the remaining terms and conditions.
                This term shall apply only within jurisdictions where a particular term is illegal.
            </p>


            <h5>
                No Waiver
            </h5>
            <p>In the event that any party to these Terms and Conditions fails to exercise any right or remedy contained
                herein, this shall not be construed as a waiver of that right or remedy.

            </p>

            <h5>
                Previous Terms and Conditions
            </h5>
            <p>In the event of any conflict between these Terms and Conditions and any prior versions thereof, the
                provisions of these Terms and Conditions shall prevail unless it is expressly stated otherwise.


            </p>

            <h5>Notices

            </h5>
            <p>All notices / communications shall be given to us either by post to our Premises (see address above) or
                by email to {Site.contactEmail}. Such notice will be deemed received 3 days after posting if sent by first
                class post, the day of sending if the email is received in full on a business day and on the next
                business day if the email is sent on a weekend or public holiday.

            </p>


            <h5>Law and Jurisdiction

            </h5>
            <p>These terms and conditions and the relationship between you and {Site.companyName} shall be governed by and
                construed in accordance with the Law of {Site.country} and {Site.companyName} and you agree to submit to the exclusive
                jurisdiction of the Courts of {Site.country}.

            </p>

        </div>
    )
};

export default TermsContent;