import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import TermsContent from "../components/TermsContent";
import ApplicantLayout from "../components/applicant/ApplicantLayout";
const  Terms =()=>{
    return(
        <ApplicantLayout>
            <Container>
                <Row>
                    <Col sm={12}>
            <h1 dir="ltr">Terms of use</h1>
            <TermsContent/>
                    </Col>
                </Row>
            </Container>
        </ApplicantLayout>);

};
export default Terms;