import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {countryList} from '../util/CountryList'

const UserRegistrationForm =(props)=>{

    const [validated,setValidated] = useState(false);
    const [inputs, setInputs] = useState({});

    useEffect(()=>{
        setInputs({...props.inputs})
    },[props.inputs])


    const handleInputChange=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name ==='password')
        {
            setInputs(inputs => ({...inputs,passwordChanged:true, [name]: value}));
        }
        else
        setInputs(inputs => ({...inputs, [name]: value}));

    };

    const  handleSubmit = (event)=>
    {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            props.onSubmitFailure(inputs);
        }
        else{

            props.onSubmit(inputs);

        }
        setValidated(true);
    };

    return (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Group   controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        name="firstName"
                        defaultValue={inputs.firstName}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback  type="invalid">Please enter first name</Form.Control.Feedback>
                </Form.Group>


                <Form.Group   controlId="lastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Last name"
                        name="lastName"
                        defaultValue={inputs.lastName}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback  type="invalid">Please enter last name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group   controlId="mobileNumber">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                        required
                        type="tel"
                        placeholder="Phone number"
                        name="mobileNumber"
                        pattern={'^([+]?[\\s0-9]+)?(\\d{3}|[(]?[0-9]+[)])?([-]?[\\s]?[0-9])+$'}
                        minLength="6"
                        defaultValue={inputs.mobileNumber}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback  type="invalid">Please enter a valid phone number</Form.Control.Feedback>
                </Form.Group>
                <Form.Group  controlId="countryOfResidence">
                    <Form.Label>Country of residence</Form.Label>
                    <Form.Control as="select"
                                  required
                                  value={inputs.countryOfResidence}
                                  onChange={handleInputChange}
                                  name="countryOfResidence"
                                  placeholder="choose"


                    >
                        <option  value="">Choose...</option>
                        {countryList.map((country)=>{
                                return  <option key={country} value={country} >{country}</option>

                        })}


                    </Form.Control>
                    <Form.Control.Feedback  type="invalid">Please choose country of residence</Form.Control.Feedback>
                </Form.Group>


                <Form.Group   controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        placeholder="Email"
                        name="email"
                        defaultValue={inputs.email}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback  type="invalid">Please enter a valid email address</Form.Control.Feedback>
                </Form.Group>

                <Form.Group    controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type="password"
                        placeholder="******"
                        name="password"
                        defaultValue={inputs.password}
                        onChange={handleInputChange}
                    />
                    <Form.Control.Feedback  type="invalid">Please enter a password</Form.Control.Feedback>
                </Form.Group>
                {
                    props.children
                }

                <Button className="btn-cta" type="submit">{props.submitButtonTitle? props.submitButtonTitle :"Register now"}</Button>
            </Form>
    );

};



export default UserRegistrationForm;