export const removeTimeFromDateTime =(dateTime)=>
{
    if(dateTime)
    return dateTime.split('T')[0];
    return dateTime;
};

/**
 * /**
 * formats the date to dd/mm/yyyy format
 * @param {Date} date  The date to format
 * @returns {string} The formatted date
 */
export const formatDateToDDMMYYYYString =(date = new Date())=>
{
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    return day+"/"+month+"/"+date.getFullYear();

}